@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

html {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}

body {
  background-color: #fff !important;
  font-size: 16px;
}

button:focus {
  outline: none;
}

.react-swipeable-view-container {
  height: 100%;
}

.vis-item-visible-frame {
  z-index: 111111;
  position: absolute;
  top: 0;
}

.vis-panel.vis-bottom {
  height: 44px;
  background-color: #f5f5f5;
  z-index: 1;
  display: flex;
  align-items: center;
}

.vis-panel .vis-time-axis .vis-text {
  color: #b2b2b2;
}

.vis-time-axis .vis-grid.vis-minor {
  border-color: #dedede;
}

.vis-time-axis.vis-background {
  z-index: 2;
}

.vis-panel.vis-center {
  z-index: 2;
}

.vis-timeline.vis-bottom {
  border: 2px solid #dedede;
}

.vis-timeline .vis-panel.vis-center {
  border-bottom-style: none;
}

.vis-custom-time.currentTime {
  z-index: 3;
}

.vis-group {
  border: none !important;
}

.vis-dot {
  left: -7px;
  border-color: #ff8600 !important;
}

.vis-dot:after {
  content: '';
  position: absolute;
  top: -6px;
  left: -6px;
  bottom: -6px;
  right: -6px;
  border-radius: 6px;
  border: 1px #bdbdbd solid;
}

.vis-item.vis-point.vis-selected {
  background-color: transparent !important;
}

.vis-item.vis-point.vis-selected > .vis-dot:after {
  border: 1px #ff8600 solid;
}

.vis-item .vis-item-content {
  white-space: nowrap;
  box-sizing: border-box;
  padding: 0 5px;
}

.scouting-action-viewer.video-js > .vjs-control-bar {
  display: none !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
